import { useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { logout } from '../../../api/adapters/auth';
import { CommonContext } from '../../../context/CommonState';
import { notify } from '../../../utils/helpers/notification.helpers';

import { useDispatch, useSelector } from 'react-redux';
import { toolTips } from '../../../api/adapters/profile';
import { clearNotification } from '../../../redux/actions/notification';
import { updateUser } from '../../../redux/actions/user';
import { initialNotificationState } from '../../../redux/reducers/notification';
import { initialUserState } from '../../../redux/reducers/user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import Footer from '../../components/dashboard/Footer';
import Header from '../../components/dashboard/Header';
import Sidebar from '../../components/dashboard/Sidebar';
import UserInstructions from '../../components/user-instructions/UserInstructions';
function Index(props) {
    const {
        activeHeader,
        modalShow,
        setModalShow,
        rewardsInstr,
        availabilityInstr,
        certificate,
        setCertificate,
        setDisplayTooltip,
        displayTooltip,
    } = useContext(CommonContext);

    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const path = useLocation();
    const scrollDom = useRef(null);
    const [userIntro, setUserIntro] = useState(false);
    const { mutate: mutateLogout } = useMutation({
        mutationFn: () => logout(i18n.language?.split('-')[0]),
        onSuccess: (data) => {
            notify('success', data.message);
            clearCookies();
            setCertificate(false);
        },
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => {
            dispatch(updateUser({ ...initialUserState }));
            dispatch(clearNotification({ ...initialNotificationState }));
            navigate('/sign-in');
        },
    });
    useEffect(() => {
        if (scrollDom.current) {
            scrollDom.current.scrollTo(0, 0);
        }
    }, [path.pathname]);

    const user = useSelector((state) => state.user);

    useEffect(() => {
        setDisplayTooltip(user?.bDisplayTooltips);
    }, [user?.bDisplayTooltips]);

    const { mutate: mutateTooltip } = useMutation({
        mutationFn: () => toolTips(),
        onSuccess: () => {},
        onError: (error) => handleApiError(error, t, navigate),
        enabled: false,
    });
    useEffect(() => {
        if (displayTooltip === true) mutateTooltip();
    }, [displayTooltip]);

    return (
        <div className='dashboard-container'>
            {user?.bDisplayTooltips ? (
                <UserInstructions setUserIntro={setUserIntro} />
            ) : (
                <>
                    {searchParams.get('device') !== 'mobile' ? (
                        <div
                            style={
                                availabilityInstr || rewardsInstr
                                    ? { zIndex: '3' }
                                    : null
                            }
                            className={`sidebar  ${
                                activeHeader && 'activeHeader'
                            }`}
                        >
                            <Sidebar />
                        </div>
                    ) : null}
                    <div
                        ref={scrollDom}
                        className={`screens-wrapper ${
                            path.pathname === '/chat' && 'chat-screen'
                        }`}
                    >
                        {searchParams.get('device') !== 'mobile' ? (
                            <Header />
                        ) : null}

                        <div
                            className={`screens-container ${
                                certificate && 'full-screen'
                            }`}
                        >
                            {props.screen}
                        </div>

                        {searchParams.get('device') !== 'mobile' ? (
                            <Footer />
                        ) : null}
                    </div>
                    <ModalPopUp
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        modalcontent={
                            <>
                                <img src='/images/icons/warn-ic.svg' alt='' />

                                <h3 className='popup-title'>{t('logout')}</h3>

                                <p className='sm'>
                                    {t('popup_content.want_to_logout')}
                                </p>

                                <div className='popup-action'>
                                    <button
                                        className='primary-btn'
                                        onClick={() => setModalShow(false)}
                                    >
                                        {t('popup_content.no_dont')}
                                    </button>
                                    <button
                                        className='simple-btn'
                                        onClick={() => {
                                            setModalShow(false);
                                            mutateLogout();
                                        }}
                                    >
                                        {t('yes')}
                                    </button>
                                </div>
                            </>
                        }
                    />
                </>
            )}
        </div>
    );
}

export default Index;
