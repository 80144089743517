import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteAccount } from '../../../api/adapters/auth';
import { toggleTalkedHoursVisibility } from '../../../api/adapters/profile';
import { updateUser } from '../../../redux/actions/user';
import { initialUserState } from '../../../redux/reducers/user';
import {
    formatPageTitle,
    handleApiError,
} from '../../../utils/helpers/common.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import {
    formatTalkedHours,
    getExpandedDateString,
    getUserFullName,
} from '../../../utils/helpers/format.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import InterestsWrapper from '../../components/interests-wrapper/InterestsWrapper';
import LoadingButton from '../../components/mini-components/LoadingButton';

function Profile() {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const [modalShow, setModalShow] = useState(false);

    const genderMap = { M: 'Male', F: 'Female', O: 'Other' };

    const { mutate: mutateDeleteAccount, isLoading: isMutating } = useMutation({
        mutationFn: () => deleteAccount(i18n.language.split('-')[0]),
        onSuccess: (data) => {
            notify('success', data.message);
            clearCookies();
        },
        onError: (error) => handleApiError(error, t, navigate),
        onSettled: () => {
            navigate('/sign-in');

            dispatch(updateUser({ ...initialUserState }));
        },
    });
    const {
        mutate: mutateToggleTalkedHoursVisibility,
        isLoading: isMutatingToggleTalkedHoursVisibility,
    } = useMutation({
        mutationFn: () =>
            toggleTalkedHoursVisibility(i18n.language.split('-')[0]),
        onSuccess: (data) => {
            notify('success', data.message);

            dispatch(
                updateUser({
                    ...user,
                    isTalkedHoursVisible: !user.isTalkedHoursVisible,
                })
            );
        },
        onError: (error) => handleApiError(error, t, navigate),
    });

    useEffect(() => {
        document.title = formatPageTitle(t('profile.profile'));
    }, [i18n.language]);

    return (
        <>
            <div className='profile'>
                <BreadcrumbAction
                    url='/profile/edit'
                    lastPage={t('profile.profile')}
                    btnTitle={t('profile.edit_profile')}
                />
                <div className='content-wrapper'>
                    <div className='row'>
                        <div className=' col-lg-12 col-xl-5  col-xxl-4'>
                            <div className='side-profile-col'>
                                <div className='duration-container'>
                                    <Link to={'/profile/edit'}>
                                        <div className='profile-dp'>
                                            <img
                                                src={
                                                    user.profilePicUrl ||
                                                    '/images/profile-image-placeholder.svg'
                                                }
                                                alt=''
                                            />
                                        </div>
                                    </Link>
                                    <div className='call-duration profile-card'>
                                        <div className='mf-card'>
                                            <h2>
                                                {formatTalkedHours(
                                                    i18n.language.split('-')[0],
                                                    user.totalCallSeconds
                                                )}
                                            </h2>
                                            <p className='small'>
                                                {user.totalCalls}{' '}
                                                {t('profile.calls')}
                                            </p>
                                        </div>
                                        {user.accountType === 'S' && (
                                            <button
                                                className={`mf-card ${
                                                    user.totalCallSeconds < 3600
                                                        ? 'disable-action'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    if (
                                                        // user.totalCalls &&
                                                        user.totalCallSeconds >=
                                                        3600
                                                    ) {
                                                        navigate(
                                                            '/chat/certificate'
                                                        );
                                                    } else {
                                                        notify(
                                                            'error',
                                                            t('chat.min_1hr')
                                                        );
                                                        return;
                                                    }
                                                }}
                                            >
                                                <img
                                                    src='/images/icons/certificate-ic.svg'
                                                    alt=''
                                                />
                                                <p className='sm'>
                                                    {t('get_certificate')}
                                                </p>
                                            </button>
                                        )}

                                        <div className='call-radio-btn'>
                                            <img
                                                src='/images/calling-hr.svg'
                                                alt=''
                                            />
                                            <Form>
                                                <Form.Check // prettier-ignore
                                                    type='switch'
                                                    id='custom-switch'
                                                    label={t('talked_Hrs')}
                                                    className='radio-btn'
                                                    checked={
                                                        user.isTalkedHoursVisible
                                                    }
                                                    onChange={() => {
                                                        mutateToggleTalkedHoursVisibility();
                                                    }}
                                                    disabled={
                                                        isMutatingToggleTalkedHoursVisibility
                                                    }
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => setModalShow(true)}
                                    className='primary-btn red-outline mt-4 mt-lg-0'
                                >
                                    {t('profile.delete_account')}
                                </button>
                            </div>
                        </div>
                        <div className=' col-lg-12 col-xl-7  col-xxl-8'>
                            <div className='user-details-wrapper'>
                                <div className='row form-space'>
                                    <div className='col-12'>
                                        <h2 className='user-name'>
                                            {getUserFullName(user)}
                                        </h2>
                                    </div>
                                    <div className='col-12'>
                                        <div className='full-content'>
                                            <div className='field-item grey-bg'>
                                                <img
                                                    src='/images/icons/user-detail-ic3.svg'
                                                    alt=''
                                                />
                                                <div className='field-data'>
                                                    <p className='small'>
                                                        {t('profile.email')}
                                                    </p>
                                                    <p className='number-flag field-value'>
                                                        {user.email}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='field-item grey-bg'>
                                                <img
                                                    src='/images/type.svg'
                                                    alt=''
                                                />
                                                <div className='field-data'>
                                                    <p className='small'>
                                                        {t('type')}
                                                    </p>
                                                    <p className='number-flag field-value'>
                                                        {user.accountType ===
                                                        'R'
                                                            ? 'Senior'
                                                            : 'Junior'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='col-md-6'>
                                        <div className='field-item grey-bg'>
                                            <img
                                                src='/images/icons/user-detail-ic1.svg'
                                                alt=''
                                            />
                                            <div className='field-data'>
                                                <p className='small'>
                                                    {t('profile.mobile_number')}
                                                </p>
                                                <p className='number-flag field-value'>
                                                    <img
                                                        src='/images/icons/Spain-flag-square.svg'
                                                        alt=''
                                                    />
                                                    {user.contactNumber}
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <div className='field-item grey-bg'>
                                            <img
                                                src='/images/icons/user-detail-ic2.svg'
                                                alt=''
                                            />
                                            <div className='field-data'>
                                                <p className='small'>
                                                    {t('profile.gender')}
                                                </p>
                                                <p className='number-flag field-value'>
                                                    {user.gender === 'M'
                                                        ? t('male')
                                                        : user.gender === 'F'
                                                        ? t('female')
                                                        : t('other')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='field-item grey-bg'>
                                            <img
                                                src='/images/icons/date-picker-icon.svg'
                                                alt=''
                                            />
                                            <div className='field-data'>
                                                <p className='small'>
                                                    {t('profile.date_of_birth')}
                                                </p>
                                                <p className='number-flag field-value'>
                                                    {getExpandedDateString(
                                                        new Date(user.dob),
                                                        i18n.language.split(
                                                            '-'
                                                        )[0]
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {user.accountType === 'R' ? (
                                        <div className='col-12'>
                                            <div className='field-item grey-bg'>
                                                <img
                                                    src='/images/icons/user-detail-ic4.svg'
                                                    alt=''
                                                />
                                                <div className='field-data'>
                                                    <p className='small'>
                                                        {t('profile.residency')}
                                                    </p>
                                                    <p className='number-flag field-value'>
                                                        {user.residency}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='col-12'>
                                            <div className='field-item grey-bg'>
                                                {/* TODO: Change this Icon */}
                                                <img
                                                    src='/images/icons/user-detail-ic4.svg'
                                                    alt=''
                                                />
                                                <div className='field-data'>
                                                    <p className='small'>
                                                        {t(
                                                            'profile.university'
                                                        )}
                                                    </p>
                                                    <p className='number-flag field-value'>
                                                        {user.university}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='col-md-6'>
                                        <div className='field-item grey-bg'>
                                            <img
                                                src='/images/icons/user-detail-ic5.svg'
                                                alt=''
                                            />
                                            <div className='field-data'>
                                                <p className='small'>
                                                    {t('profile.city')}
                                                </p>
                                                <p className='number-flag field-value'>
                                                    {user.city}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='field-item grey-bg'>
                                            <img
                                                src='/images/icons/user-detail-ic6.svg'
                                                alt=''
                                            />
                                            <div className='field-data'>
                                                <p className='small'>
                                                    {t('profile.country')}
                                                </p>
                                                <p className='number-flag field-value'>
                                                    {user.country}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <Link to={'/profile/edit'}>
                                            <div className='field-item'>
                                                <img
                                                    src='/images/icons/user-detail-ic7.svg'
                                                    alt=''
                                                />
                                                <div className='field-data'>
                                                    <p className='small'>
                                                        {t('profile.bio')}
                                                    </p>
                                                    <p className='number-flag field-value'>
                                                        {user.bio}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <Link to='/profile/edit'>
                                    <div className='user-interest not-edited'>
                                        <h3>{t('profile.interests')}</h3>

                                        <InterestsWrapper
                                            interests={user.interests}
                                            isSelectable={false}
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />
                        <h3 className='popup-title'>
                            {t('profile.delete_account')}
                        </h3>
                        <p className='sm'>
                            {t('popup_content.delete_permanently')}
                        </p>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setModalShow(false)}
                            >
                                {t('popup_content.no_dont')}
                            </button>
                            <button
                                className='simple-btn'
                                disabled={isMutating}
                                onClick={(e) => {
                                    e.preventDefault();
                                    mutateDeleteAccount();
                                }}
                            >
                                {isMutating ? (
                                    <LoadingButton color='White' />
                                ) : (
                                    t('popup_content.yes_delete')
                                )}
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

export default Profile;
