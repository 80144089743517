import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendOtp, verifyOtp } from '../../../api/adapters/auth';
import { formatPageTitle } from '../../../utils/helpers/common.helpers';
import {
    setCallToken,
    setToken,
    setUserId,
} from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import LeftTextAnimation from '../../components/account-setup-component/LeftTextAnimation';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import LangSelector from '../../components/mini-components/LangSelector';
import LoadingButton from '../../components/mini-components/LoadingButton';

function OtpVerification() {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const { email } = location.state || '';

    const timerRef = useRef(null);

    const [resendOtpTimer, setResendOtpTimer] = useState(60);

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');

    const { mutate: mutateSendOtp, isLoading: isMutatingSendOtp } = useMutation(
        {
            mutationFn: (data) => sendOtp(i18n.language?.split('-')[0], data),
            onSuccess: (response) => {
                notify('success', response.message);
            },
            onError: (error) => {
                notify(
                    'error',
                    error.message || t('common.errors.something_went_wrong')
                );
            },
        }
    );

    const { mutate: mutateVerifyOtp, isLoading: isMutating } = useMutation({
        mutationFn: (data) => verifyOtp(i18n.language?.split('-')[0], data),
        onSuccess: (response) => {
            notify('success', response.message);
            if (location.state?.reAuth) {
                setToken(response.data.sToken);
                setUserId(response.data.sUserId);
                setCallToken(response.data.sCallToken);
                setInterval(() => {
                    return (window.location.href = '/profile');
                }, 1000);
            } else {
                navigate('/create-account', {
                    replace: true,
                    state: { ...location.state },
                });
            }
        },
        onError: (error) => {
            if (error.status === 422) {
                setOtpError(error.data.errors[0].msg);
            } else if (error.message) {
                setOtpError(error.message);
            } else {
                notify(
                    'error',
                    error?.message || t('common.errors.something_went_wrong')
                );
            }
        },
    });

    const startTimer = () => {
        setResendOtpTimer(90);

        if (timerRef.current !== null) clearInterval(timerRef.current);

        timerRef.current = setInterval(() => {
            setResendOtpTimer((prevOtpTimer) => {
                if (prevOtpTimer <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prevOtpTimer - 1;
            });
        }, 1000);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (!otp.length) return;

        if (otp.length !== 4)
            return setOtpError(t('otp_page.errors.error_invalid_otp'));

        setOtpError('');
        if (location.state?.reAuth) {
            mutateVerifyOtp({
                sEmail: email,
                nOtp: otp,
                isUnsuccessAttempt: true,
            });
        } else {
            mutateVerifyOtp({
                sEmail: email,
                nOtp: otp,
                isUnsuccessAttempt: false,
            });
        }
    };

    useEffect(() => {
        document.title = formatPageTitle(t('otp_page.title'));

        if (otpError) setOtpError(t('otp_page.errors.error_invalid_otp'));
    }, [i18n.language]);

    useEffect(() => {
        if (
            // !location.state?.firstName ||
            // !location.state?.middleName ||
            !location.state?.email
            // !location.state?.reAuth
            // !location.state?.confirmPassword
        ) {
            navigate('/sign-up', { replace: true });
        }
    }, [location.state]);

    useEffect(() => startTimer(), []);

    const handleResentOtp = (e) => {
        e.preventDefault();
        if (resendOtpTimer) return;
        if (location.state?.reAuth) {
            mutateSendOtp({ sEmail: email, isUnsuccessAttempt: true });
        } else {
            mutateSendOtp({ sEmail: email, isUnsuccessAttempt: false });
        }
        startTimer();
    };
    return (
        <div className='account-setup'>
            <LeftTextAnimation GifShow={true} />
            <div className='right-screen'>
                <div className='language-selector'>
                    <LangSelector i18n={i18n} />

                    <div className='account-setup-wrapper padding-top-120'>
                        <h1 className='text-center'>{t('otp_page.title')}</h1>
                        <form
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            <div>
                                <p className='text-center'>
                                    {t('otp_page.description')}{' '}
                                </p>
                                <span className='edit-number mb-2'>
                                    {email}
                                    <span
                                        role='button'
                                        className='simple-btn d-flex'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/sign-up', {
                                                replace: true,
                                                state: { ...location.state },
                                            });
                                        }}
                                    >
                                        <img
                                            src='/images/icons/edit-number.svg'
                                            alt=''
                                        />
                                    </span>
                                </span>
                            </div>
                            <div className='otp-verification'>
                                <OtpInput
                                    shouldAutoFocus
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    inputType='number'
                                    placeholder='----'
                                    renderSeparator={null}
                                    inputStyle='otp-input'
                                    containerStyle='otp-wrapper'
                                    renderInput={(props) => (
                                        <input {...props} />
                                    )}
                                />
                                {otpError ? (
                                    <FormErrorLabel message={otpError} />
                                ) : null}
                            </div>

                            <div>
                                <div className='opt-resent-wrapper'>
                                    <p>
                                        {t('otp_page.resend_title')}{' '}
                                        {resendOtpTimer ? (
                                            <span>
                                                {resendOtpTimer}{' '}
                                                {t('otp_page.time_formate')}
                                            </span>
                                        ) : null}
                                    </p>
                                    <button
                                        className='simple-btn'
                                        disabled={
                                            !!resendOtpTimer ||
                                            isMutatingSendOtp
                                        }
                                        style={
                                            !resendOtpTimer &&
                                            !isMutatingSendOtp
                                                ? { color: '#0080ff' }
                                                : {}
                                        }
                                        onClick={handleResentOtp}
                                    >
                                        {t('otp_page.resend')}
                                    </button>
                                </div>
                                <div className='action-btn'>
                                    <button
                                        className={`primary-btn w-100 ${
                                            otp.toString().length === 4
                                                ? ''
                                                : 'disable-btn'
                                        }`}
                                        disabled={
                                            isMutating || isMutatingSendOtp
                                        }
                                    >
                                        {isMutating ? (
                                            <LoadingButton color='White' />
                                        ) : (
                                            t('submit_btn')
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OtpVerification;
