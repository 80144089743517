import React from 'react';
import { useTranslation } from 'react-i18next';

const SpeakNowActive = () => {
    const { t } = useTranslation();

    return (
        <div className='speak-content'>
            <h1>{t('dialog_now.dialognow')}</h1>
            <p className='lg pt-2'>
                <span className='f-600'>{t('dialog_now.spin_the')}</span>{' '}
                {t('dialog_now.to_find')}
                <br />
                <span className='f-600'>{t('dialog_now.lets')}</span>
            </p>
        </div>
    );
};

export default SpeakNowActive;
