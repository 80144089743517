import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CommonContext } from '../../../context/CommonState';
import { updateUser } from '../../../redux/actions/user';
import { getUserFullName } from '../../../utils/helpers/format.helpers';
import ModalPopUp from '../ModalPopUp';

function Sidebar({ setIntroStep, introStep }) {
    const { t } = useTranslation();
    const notification = useSelector((state) => state.notification);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        setModalShow,
        availabilityInstr,
        confirmModalTo,
        setConfirmModalTo,
        setShowConfirmModal,
        setIsFormModified,
        confirmNavigation,
        showConfirmModal,
    } = useContext(CommonContext);
    const user = useSelector((state) => state.user);
    const location = useLocation();

    const { status } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let nTotalCallNotification = notification?.filter((notification) => {
        return (
            notification.sNotificationType === 'IMAGE' ||
            notification.sNotificationType === 'TEXT' ||
            notification.sNotificationType === 'AUDIO'
        );
    });
    // let nTotalCallNotification = notification?.filter((notification) => {
    //     return Object.values(notification).some((value) => value);
    // });

    let nTotalAppointmentNotification = notification?.filter((notification) => {
        return (
            notification.sNotificationType !== 'IMAGE' &&
            notification.sNotificationType !== 'TEXT' &&
            notification.sNotificationType !== 'AUDIO' &&
            (notification.bIsRead ||
                notification.dCreatedAt ||
                notification.dUpdatedAt ||
                notification.iConversationId ||
                notification.iRecipient ||
                notification.sContent ||
                notification.sNotificationType ||
                notification.sSenderName ||
                notification._id)
        );
    });
    // console.log(nTotalCallNotification);

    const handleNext = () => {
        setIntroStep(introStep + 1);
        if (user?.accountType === 'S' && introStep === 9) {
            dispatch(updateUser({ bDisplayTooltips: false }));
        }
        if (user?.accountType !== 'S' && introStep === 8) {
            dispatch(updateUser({ bDisplayTooltips: false }));
        }
    };

    // const isActive = (path) => {
    //     return location.pathname === path;
    // };
    const isActive = (path) => {
        if (path === '/') {
            return location.pathname === '/';
        }
        return (
            location.pathname.startsWith(path) &&
            location.pathname.split('/')[1] === path.split('/')[1]
        );
    };

    const generateNavLink = (path, introStep) => (
        <Link
            className={`msg-counter-link ${
                isActive(`/appointments/${path}`) ? 'active' : ''
            } ${introStep === 5 ? 'active' : ''}`}
            style={
                introStep === 5
                    ? {
                          position: 'relative',
                          zIndex: '6',
                      }
                    : null
            }
            // to={status ? `/appointments/${status}` : `/appointments/${path}`}
            onClick={() =>
                handleMenuItemClick(
                    status ? `/appointments/${status}` : `/appointments/${path}`
                )
            }
        >
            <span>
                <img src='/images/icons/nav-ic2.svg' alt='' />{' '}
                <span>{t('dashboard_nav.appointments')}</span>
            </span>
            {nTotalAppointmentNotification?.length > 0 && (
                <div className='msg-counter'>
                    {nTotalAppointmentNotification?.length}
                </div>
            )}
        </Link>
    );

    // const handleMenuItemClick = (path) => {
    //     if (isFormModified) {
    //         const confirmed = window.confirm(
    //             'Changes you made may not be saved. Are you sure you want to leave?'
    //         );
    //         if (confirmed) {
    //             setIsFormModified(false);
    //             navigate(path);
    //         }
    //     } else {
    //         navigate(path);
    //     }
    // };

    const handleMenuItemClick = (path) => {
        confirmNavigation(path);
        setIsFormModified(false);
        // setShowConfirmModal(true);
    };
    const handleConfirmModalHide = () => {
        setShowConfirmModal(false);
        setConfirmModalTo(null);
        setIsFormModified(false);
    };
    const handleDiscard = () => {
        setIsFormModified(false);
        navigate(confirmModalTo);
        handleConfirmModalHide();
    };
    const handleContinue = () => {
        handleConfirmModalHide();
        navigate('/profile/edit');
    };

    const handleSkip = () => {
        dispatch(updateUser({ bDisplayTooltips: false }));
    };

    return (
        <div className='main-sidebar'>
            <div className='sidebar-menu'>
                {!isMobile && (
                    <Link to={'/'} onClick={() => handleMenuItemClick('/')}>
                        <div className='logo'>
                            <img src='/images/icons/sidebar-logo.svg' alt='' />
                        </div>
                    </Link>
                )}
                {isMobile && (
                    <Dropdown>
                        <Dropdown.Toggle
                            variant='unset'
                            id='dropdown-basic'
                            className='mobile-profile-btn'
                        >
                            <div className='toggle-wrapper'>
                                <button className='simple-btn mobile-profile'>
                                    <div className='mobile-dp'>
                                        <img src={user.profilePicUrl} alt='' />
                                    </div>

                                    <p className='user-name lg'>
                                        {getUserFullName(user)}
                                        <img
                                            className='arrow'
                                            src='/images/icons/dropdown.svg'
                                            alt=''
                                        ></img>
                                    </p>
                                </button>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item to='/profile' as={Link}>
                                <span>
                                    <img
                                        src='/images/icons/profile-ic1.svg'
                                        alt=''
                                    />{' '}
                                    {t('profile_nav.profile')}
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <span>
                                    <img
                                        src='/images/icons/profile-ic2.svg'
                                        alt=''
                                    />{' '}
                                    {t('profile_nav.share_with_friends')}
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setModalShow(true)}>
                                <span>
                                    <img
                                        src='/images/icons/profile-ic3.svg'
                                        alt=''
                                    />{' '}
                                    {t('logout')}
                                </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                <div className='navigation'>
                    <ul>
                        <li className='navbar-item'>
                            <Link
                                // to='/'
                                className={`${isActive('/') ? 'active' : ''}`}
                                onClick={() => handleMenuItemClick('/')}
                            >
                                <img src='/images/icons/home-ic.svg' alt='' />{' '}
                                <span>{t('dashboard_nav.home')}</span>
                            </Link>
                        </li>
                        <li className='navbar-item'>
                            {/* <NavLink to='/appointments/schedule'>
                                <img src='/images/icons/nav-ic2.svg' alt='' />{' '}
                                <span>{t('dashboard_nav.appointments')}</span>
                            </NavLink> */}
                            {generateNavLink('schedule', introStep)}
                            {introStep === 5 ? (
                                <div className='tutorial-screen appointments'>
                                    <div
                                        className={`tutorial ${
                                            availabilityInstr ? '' : null
                                        }`}
                                    >
                                        <img
                                            className='arrow'
                                            src='/images/home-tut-arrow.svg'
                                            alt=''
                                        />
                                        <p className='lg'>
                                            {t('dashboard_nav.appointments')}
                                        </p>
                                    </div>
                                    <div className='instr-action'>
                                        <button
                                            className='primary-btn outline-btn-white'
                                            onClick={handleSkip}
                                        >
                                            {t('instruction.skip')}
                                        </button>
                                        <button
                                            className='primary-btn'
                                            onClick={handleNext}
                                        >
                                            {t('instruction.next')}
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                        <li className='navbar-item'>
                            <Link
                                style={
                                    introStep === 6
                                        ? {
                                              position: 'relative',
                                              zIndex: '6',
                                          }
                                        : null
                                }
                                // to='/availability'
                                onClick={() =>
                                    handleMenuItemClick('/availability')
                                }
                                // className={introStep === 6 ? 'active ' : ''}
                                className={`${
                                    isActive('/availability') ? 'active' : ''
                                } ${introStep === 6 ? 'active' : ''}`}
                            >
                                <img src='/images/icons/nav-ic3.svg' alt='' />{' '}
                                <span>{t('dashboard_nav.availability')}</span>
                            </Link>
                            {introStep === 6 ? (
                                <div className='tutorial-screen availability'>
                                    <div className={`tutorial`}>
                                        <img
                                            className='arrow'
                                            src='/images/home-tut-arrow.svg'
                                            alt=''
                                        />
                                        <p className='lg'>
                                            {t('instruction.forget_to')}
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                        <li className='navbar-item'>
                            <Link
                                // to='/speak-now'
                                style={
                                    introStep === 7
                                        ? {
                                              position: 'relative',
                                              zIndex: '6',
                                          }
                                        : null
                                }
                                onClick={() =>
                                    handleMenuItemClick('/speak-now')
                                }
                                // className={introStep === 7 ? 'active ' : ''}
                                className={`${
                                    isActive('/speak-now') ? 'active' : ''
                                } ${introStep === 7 ? 'active' : ''}`}
                            >
                                <img src='/images/icons/nav-ic4.svg' alt='' />{' '}
                                <span>{t('dashboard_nav.speak_now')}</span>
                            </Link>

                            {introStep === 7 ? (
                                <div className='tutorial-screen speak-intro'>
                                    <div className={`tutorial`}>
                                        <img
                                            className='arrow'
                                            src='/images/home-tut-arrow.svg'
                                            alt=''
                                        />
                                        <p className='lg'>
                                            {t('dashboard_nav.speak_now')}
                                        </p>
                                    </div>
                                    <div className='instr-action'>
                                        <button
                                            className='primary-btn outline-btn-white'
                                            onClick={handleSkip}
                                        >
                                            {t('instruction.skip')}
                                        </button>
                                        <button
                                            className='primary-btn'
                                            onClick={handleNext}
                                        >
                                            {t('instruction.next')}
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                        <li className='navbar-item'>
                            <Link
                                // to='/chat'
                                onClick={() => handleMenuItemClick('/chat')}
                                style={
                                    introStep === 8
                                        ? {
                                              position: 'relative',
                                              zIndex: '6',
                                          }
                                        : null
                                }
                                className={`${
                                    isActive('/chat') ? 'active' : ''
                                }${
                                    introStep === 8
                                        ? 'active msg-counter-link'
                                        : 'msg-counter-link'
                                    // introStep === 8 ? 'active' : ''
                                }`}
                            >
                                <span>
                                    <img
                                        src='/images/icons/nav-ic5.svg'
                                        alt=''
                                    />{' '}
                                    <span>{t('dashboard_nav.chat')}</span>
                                </span>
                                {nTotalCallNotification?.length > 0 && (
                                    <div className='msg-counter'>
                                        {nTotalCallNotification?.length}
                                    </div>
                                )}
                            </Link>
                            {introStep === 8 ? (
                                <div className='tutorial-screen chat'>
                                    <div className={`tutorial`}>
                                        <img
                                            className='arrow'
                                            src='/images/home-tut-arrow.svg'
                                            alt=''
                                        />
                                        <p className='lg'>
                                            {t('dashboard_nav.chat')}
                                        </p>
                                    </div>
                                    <div className='instr-action'>
                                        <button
                                            className='primary-btn outline-btn-white'
                                            onClick={handleSkip}
                                        >
                                            {t('instruction.skip')}
                                        </button>
                                        <button
                                            className='primary-btn'
                                            onClick={handleNext}
                                        >
                                            {t('instruction.next')}
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </li>
                        {user.accountType === 'S' ? (
                            <li className='navbar-item'>
                                <Link
                                    // to='/rewards'
                                    onClick={() =>
                                        handleMenuItemClick('/rewards')
                                    }
                                    style={
                                        introStep === 9
                                            ? {
                                                  position: 'relative',
                                                  zIndex: '6',
                                              }
                                            : null
                                    }
                                    // className={introStep === 9 ? 'active' : ''}
                                    className={`${
                                        isActive('/rewards') ? 'active' : ''
                                    } ${introStep === 9 ? 'active' : ''}`}
                                >
                                    <img
                                        src='/images/icons/nav-ic6.svg'
                                        alt=''
                                    />{' '}
                                    <span>{t('dashboard_nav.rewards')}</span>
                                </Link>
                                {introStep === 9 ? (
                                    <div className='tutorial-screen reward'>
                                        <div className={`tutorial`}>
                                            <img
                                                className='arrow'
                                                src='/images/home-tut-arrow.svg'
                                                alt=''
                                            />
                                            <p className='lg'>
                                                {t('dashboard_nav.rewards')}
                                            </p>
                                        </div>
                                        <div className='instr-action'>
                                            <button
                                                className='primary-btn outline-btn-white'
                                                onClick={handleSkip}
                                            >
                                                {t('instruction.skip')}
                                            </button>
                                            <button
                                                className='primary-btn'
                                                onClick={() => {
                                                    handleNext();
                                                }}
                                            >
                                                {t('instruction.next')}
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </li>
                        ) : null}

                        {/* <li className='navbar-item'>
                            <NavLink to='/video-call-demo'>
                                <img src='/images/icons/nav-ic4.svg' alt='' />{' '}
                                <span>Video Call Demo</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className='navbar-item'>
                <button
                    className='simple-btn logout-btn logout-button'
                    onClick={() => setModalShow(true)}
                >
                    <img src='/images/icons/nav-ic7.svg' alt='' />{' '}
                    <span>{t('logout')}</span>
                </button>
            </div>

            {showConfirmModal && (
                <ModalPopUp
                    show={showConfirmModal}
                    onHide={handleConfirmModalHide}
                    modalcontent={
                        <>
                            <img src='/images/icons/warn-ic.svg' alt='' />
                            <h3 className='popup-title'>
                                Cambios no guardados
                            </h3>
                            <p className='sm'>
                                Los cambios que realizaste no sean han guardado.
                                ¿Estás seguro de que quieres salir?
                            </p>
                            <div className='popup-action'>
                                <button
                                    className='simple-btn'
                                    onClick={handleDiscard}
                                >
                                    Descartar
                                </button>
                                <button
                                    className='primary-btn'
                                    onClick={handleContinue}
                                >
                                    Continuar
                                </button>
                            </div>
                        </>
                    }
                />
            )}
        </div>
    );
}

export default Sidebar;
