import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    getSingleDialoguer,
    scheduleCall,
} from '../../../api/adapters/appointment';
import {
    getAvailability,
    setAvailability,
} from '../../../api/adapters/availability';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import Categories from '../../components/common-component/Categories';
import TimeSlots from '../../components/common-component/TimeSlots';
import TitleHeader from '../../components/common-component/TitleHeader';
import DateSelector from '../../components/mini-components/DateSelector';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import LoadingButton from '../../components/mini-components/LoadingButton';
import TextArea from '../../components/mini-components/TextArea';
import HomeInstructions from './HomeInstructions';
import UserDetails from './UserDetails';

function ScheduleCall(props) {
    const { interestHeading = false } = props;

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const bFromCall = location.state?.bFromCall || false;

    const [selectedDate, setSelectedDate] = useState(null);
    const [slots, setSlots] = useState([]);
    const [message, setMessage] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [availabilityModalShow, setAvailabilityModalShow] = useState(false);
    const [ScheduleCallInstr, setScheduleCallInstr] = useState(false);
    const [isAvailabilityRefetched, setIsAvailabilityRefetched] =
        useState(false);

    const [formErrors, setFormErrors] = useState({});

    const {
        data: availabilityData,
        isLoading: isLoadingAvailabilityData,
        refetch: refetchAvailability,
        error: availabilityError,
    } = useQuery({
        queryKey: ['availabilities', getUserId(), i18n.language.split('-')[0]],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                getUserId(),
                false,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
    });

    const {
        data: dialoguerData,
        isFetching: isFetchingDialoguerData,
        refetch: refetchDialoguerData,
        error: dialoguerDataError,
    } = useQuery({
        queryKey: ['dialoguer', i18n.language.split('-')[0], id],
        queryFn: () =>
            getSingleDialoguer(i18n.language.split('-')[0], id).then(
                (res) => res.data || null
            ),
        // enabled: false,
    });

    const {
        data: dialoguerAvailabilityData,
        isFetching: isFetchingDialoguerAvailabilityData,
        refetch: refetchDialoguerAvailabilityData,
        error: dialoguerAvailabilityError,
    } = useQuery({
        queryKey: [
            'availabilities',
            dialoguerData?._id,
            i18n.language.split('-')[0],
        ],
        queryFn: () =>
            getAvailability(
                i18n.language.split('-')[0],
                dialoguerData._id,
                false,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ).then((res) => res.data),
        // enabled: false,
    });

    const { mutate: mutateSetAvailability, isLoading: isAvailabilityMutating } =
        useMutation({
            mutationFn: (data) =>
                setAvailability(
                    i18n.language.split('-')[0],
                    data,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                // notify('success', response.message);
                refetchAvailability();
                setIsAvailabilityRefetched(true);
                setAvailabilityModalShow(false);
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
        });

    const { mutate: mutateScheduleCall, isLoading: isScheduleCallMutating } =
        useMutation({
            mutationFn: (data) =>
                scheduleCall(
                    i18n.language.split('-')[0],
                    dialoguerData._id,
                    data,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ),
            onSuccess: (response) => {
                notify('success', response.message);
                navigate('/appointments/request');
            },
            onError: (error) =>
                handleApiError(error, t, navigate, setFormErrors),
            onSettled: () => refetchDialoguerData(),
        });

    useEffect(() => {
        if (isAvailabilityRefetched) handleSubmit();
    }, [availabilityData, isAvailabilityRefetched]);

    useEffect(() => {
        if (dialoguerAvailabilityData?.length)
            setSelectedDate(
                moment(dialoguerAvailabilityData[0].dDate).format()
            );
    }, [dialoguerAvailabilityData]);

    useEffect(() => {
        const index = dialoguerAvailabilityData?.findIndex((item) => {
            return moment.utc(item.dDate).isSame(moment.utc(selectedDate));
        });

        const updatedSlots = dialoguerAvailabilityData?.[index]?.aSlots?.map(
            (item) => {
                return {
                    from: moment(item.dStartTime).format('HH:mm'),
                    to: moment(item.dEndTime).format('HH:mm'),
                    availabilityId: item.iAvailabilityId,
                    id: item._id,
                    isSelected: false,
                };
            }
        );

        setSlots(updatedSlots);
    }, [selectedDate, dialoguerAvailabilityData]);

    const handleMessageChange = (e) => {
        const inputValue = e.target.value;
        const charactersCount = inputValue.length;

        if (charactersCount <= 100) setMessage(inputValue);
    };

    const handleSetAvailability = () => {
        let selectedSlots = slots
            .filter((item) => item.isSelected)
            .map((item) => ({ sStartTime: item.from, sEndTime: item.to }));

        const index = availabilityData?.findIndex((item) =>
            moment.utc(item.dDate).isSame(moment.utc(selectedDate))
        );

        if (index >= 0) {
            const additionalSlots = availabilityData[index].aSlots.map(
                (item) => {
                    return {
                        sStartTime: moment(item.dStartTime).format('HH:mm'),
                        sEndTime: moment(item.dEndTime).format('HH:mm'),
                    };
                }
            );

            selectedSlots = [...selectedSlots, ...additionalSlots];

            selectedSlots = selectedSlots.filter(
                (slot, index, self) =>
                    index ===
                    self.findIndex(
                        (slot2) =>
                            slot2.sStartTime === slot.sStartTime &&
                            slot2.sEndTime === slot.sEndTime
                    )
            );
        }

        const payload = {
            dStartDate: moment(selectedDate).format('YYYY-MM-DD'),
            dEndDate: moment(selectedDate).format('YYYY-MM-DD'),
            aSlots: selectedSlots,
            sType: 'once',
            nAlertBefore: 5,
        };
        mutateSetAvailability(payload);
    };

    const handleSubmit = () => {
        const selectedSlots = slots?.filter((item) => item.isSelected);

        const errors = formErrors;

        // Validate Date
        if (!selectedDate)
            errors.dDate = t('appointments.errors.date_required');
        else errors.dDate = '';

        // Validate Slots
        if (!selectedSlots?.length)
            errors.aSelectedSlots = t('appointments.errors.slots_required');
        else errors.aSelectedSlots = '';

        // Validate Message
        if (message.length > 50)
            errors.sMessage = t('appointments.errors.message_length');
        else errors.sMessage = '';

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        const index = availabilityData?.findIndex((item) => {
            return (
                moment(item.dDate).format('DD-MM-YYYY') ===
                moment(selectedDate).format('DD-MM-YYYY')
            );
        });
        try {
            if (index >= 0) {
                const aSlots = availabilityData[index].aSlots.filter((item) => {
                    const slotTime = moment(item.dStartTime).format('HH:mm');
                    const selectedSlot = selectedSlots?.find(
                        (slot) => slot.from === slotTime
                    );
                    if (selectedSlot?.bHasAppointment)
                        throw new Error(
                            t('appointments.errors.slot_not_available')
                        );
                    return selectedSlot;
                });
                if (aSlots?.length !== selectedSlots?.length) {
                    setAvailabilityModalShow(true);
                    return;
                }
            } else {
                setAvailabilityModalShow(true);
                return;
            }
        } catch (error) {
            notify('info', error.message);
            return;
        }

        const payload = {
            dDate: moment(selectedDate).format('YYYY-MM-DD'),
            aSelectedSlots: selectedSlots.map((item) => {
                return {
                    availabilityId: item.availabilityId,
                    id: item.id,
                };
            }),
            sMessage: message,
            bFromCall: bFromCall,
        };
        mutateScheduleCall(payload);
    };

    const handleBack = () => {
        if (bFromCall === false) {
            navigate('/');
        } else {
            navigate(-1);
        }
    };

    return (
        <div className='schedule-call content-wrapper h-100'>
            <TitleHeader
                blueTitle={true}
                hideBtn={false}
                title={t('home.schedule_call')}
                isBtnDisabled={isScheduleCallMutating}
                btn_title={
                    isScheduleCallMutating ? (
                        <LoadingButton color='White' />
                    ) : (
                        t('home.submit')
                    )
                }
                btnClick={handleSubmit}
                isBackBtn={handleBack}
            />
            <div className='schedule-call-data'>
                <div className='row'>
                    <UserDetails dialoguerData={dialoguerData} />

                    <div className='col-md-8'>
                        {interestHeading && (
                            <p className='lg interest-heading'>
                                {t('home,interests')}
                            </p>
                        )}
                        {dialoguerData?.aMatchedInterests?.length ? (
                            <div className='matched'>
                                <Categories
                                    interestItems={dialoguerData?.aMatchedInterests.reduce(
                                        (acc, cur) => {
                                            const categoryInterestItems =
                                                cur.interestCategory.aInterestItems.map(
                                                    (item) => ({
                                                        sTitle: item.sTitle,
                                                        sIconUrl: item.sIconUrl,
                                                    })
                                                );
                                            acc.push(...categoryInterestItems);
                                            return acc;
                                        },
                                        []
                                    )}
                                    isSelectable={false}
                                    title={t('home.matched_interests')}
                                />
                            </div>
                        ) : null}
                        <div className='grey-bg'>
                            <h3 className='pt-24'>
                                {t('home.book_appointment')}
                            </h3>
                            <div className='row form-space'>
                                <div className='col-sm-6'>
                                    <DateSelector
                                        value={selectedDate}
                                        handleDateChange={(date) => {
                                            setSelectedDate(date);
                                        }}
                                        label={t('appointments.date') + '*'}
                                        startYear={new Date().getFullYear()}
                                        endYear={new Date().getFullYear() + 2}
                                        placeholder={t('home.select_date')}
                                        includeDates={
                                            dialoguerAvailabilityData?.map(
                                                (item) => new Date(item.dDate)
                                            ) || []
                                        }
                                        errorMessage={formErrors.dDate}
                                    />
                                </div>
                                {slots?.length ? (
                                    <div className='col-12'>
                                        <TimeSlots
                                            slotLabel={
                                                t('home.select_time') + '*'
                                            }
                                            colorLabel='#666'
                                            slots={slots}
                                            setSlots={setSlots}
                                        />
                                        {formErrors.aSelectedSlots ? (
                                            <FormErrorLabel
                                                message={
                                                    formErrors.aSelectedSlots
                                                }
                                            />
                                        ) : null}
                                    </div>
                                ) : null}

                                <div className='col-12'>
                                    <TextArea
                                        placeholder={t('home.text_placeholder')}
                                        label={t('home.chat_message')}
                                        chatIcon={true}
                                        value={message}
                                        charactersCount={message.length}
                                        errorMessage={formErrors.sMessage}
                                        handleOnChange={handleMessageChange}
                                    />
                                </div>
                            </div>
                            <button
                                className='primary-btn mt-3'
                                onClick={handleSubmit}
                                disabled={isScheduleCallMutating}
                            >
                                {isScheduleCallMutating ? (
                                    <LoadingButton color='White' />
                                ) : (
                                    t('home.submit')
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Incomplete Appointment */}
            <ModalPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('home.incomplete_appointment')}
                        </h3>

                        <p className='sm'>{t('home.if_you_do')}</p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setModalShow(false)}
                            >
                                {t('home.complete')}
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => {
                                    setModalShow(false);
                                }}
                            >
                                {t('home.leave')}
                            </button>
                        </div>
                    </>
                }
            />

            <ModalPopUp
                show={availabilityModalShow}
                onHide={() => setAvailabilityModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {t('home.missing_availability')}
                        </h3>

                        <p className='sm'>{t('home.you_have_not')}</p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={handleSetAvailability}
                            >
                                {isAvailabilityMutating ? (
                                    <LoadingButton color='White' />
                                ) : (
                                    t('instruction.set_availability')
                                )}
                            </button>
                            <button
                                className='simple-btn'
                                onClick={() => {
                                    setAvailabilityModalShow(false);
                                }}
                            >
                                {t('home.change_slots')}
                            </button>
                        </div>
                    </>
                }
            />
            {ScheduleCallInstr && <HomeInstructions swipeArrow={true} />}
        </div>
    );
}

ScheduleCall.propTypes = {
    dialoguerData: PropTypes.shape({
        _id: PropTypes.string,
        oName: PropTypes.shape({
            sFirstName: PropTypes.string,
            sMiddleName: PropTypes.string,
            sLastName: PropTypes.string,
        }),
        sBio: PropTypes.string,
        sProfilePicUrl: PropTypes.string,
        aMatchedInterests: PropTypes.arrayOf(
            PropTypes.shape({
                interestCategory: PropTypes.shape({
                    _id: PropTypes.string,
                    sTitle: PropTypes.string,
                    aInterestItems: PropTypes.arrayOf(
                        PropTypes.shape({
                            _id: PropTypes.string,
                            sTitle: PropTypes.string,
                            sIconUrl: PropTypes.string,
                            interestCategory: PropTypes.shape({
                                _id: PropTypes.string,
                                sTitle: PropTypes.string,
                            }),
                        })
                    ),
                }),
            })
        ),
        aUnmatchedInterests: PropTypes.arrayOf(
            PropTypes.shape({
                interestCategory: PropTypes.shape({
                    _id: PropTypes.string,
                    sTitle: PropTypes.string,
                    aInterestItems: PropTypes.arrayOf(
                        PropTypes.shape({
                            _id: PropTypes.string,
                            sTitle: PropTypes.string,
                            sIconUrl: PropTypes.string,
                            interestCategory: PropTypes.shape({
                                _id: PropTypes.string,
                                sTitle: PropTypes.string,
                            }),
                        })
                    ),
                }),
            })
        ),
        oCity: PropTypes.shape({
            sTitle: PropTypes.string,
        }),
        oCountry: PropTypes.shape({
            sTitle: PropTypes.string,
        }),
    }),
    dialoguerAvailabilityData: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            dDate: PropTypes.string,
            dLastSlotTime: PropTypes.string,
            aSlots: PropTypes.arrayOf(
                PropTypes.shape({
                    dStartTime: PropTypes.string,
                    dEndTime: PropTypes.string,
                    dAlertTime: PropTypes.string,
                    bHasAppointment: PropTypes.bool,
                    _id: PropTypes.string,
                })
            ),
        })
    ),
};

export default ScheduleCall;
