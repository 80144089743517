import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDialogaeNowTime } from '../../../api/adapters/dialogae-now';
import { CommonContext } from '../../../context/CommonState';
import {
    formatPageTitle,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { getUserId } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import Loader from '../../components/common-component/Loader';
import CallWheeler from '../../components/speak-now/CallWheeler';
import NoDialoguerFound from './NoDialoguerFound';
import SpeakNowActive from './SpeakNowActive';
import SpeakNowCountdown from './SpeakNowCountdown';
import SpeakNowNotActive from './SpeakNowNotActive';

function SpeakNow() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { socketCommon } = useContext(CommonContext);
    const [buttonShow, setButtonShow] = useState(false);

    const [wheelRotate, setWheelRotate] = useState(false);
    const [isSpeakNowActive, setIsSpeakNowActive] = useState(false);

    const user = useSelector((state) => state.user);

    const {
        data: dialogaeNowData,
        refetch: refetchDialogaeNowTime,
        isLoading: isLoadingDialogaeNowTime,
        isRefetching: isRefetchingDialogaeNowTime,
        error: dialogaeNowDataError,
    } = useQuery({
        queryKey: ['dialogae-now-time', i18n.language.split('-')[0]],
        queryFn: () =>
            getDialogaeNowTime(i18n.language.split('-')[0]).then(
                (res) => res.data
            ),
    });

    useEffect(() => {
        if (dialogaeNowDataError)
            handleQueryError(dialogaeNowDataError, navigate);
    }, [dialogaeNowDataError]);

    const wheelerHandle = () => {
        setWheelRotate(true);
        setButtonShow(true);
        let intervalStarted = false;
        const startTime = Date.now();
        let triggerInterval;

        const handleNoDialoguer = (data) => {
            if (!intervalStarted && data.iUser === user.id) {
                intervalStarted = true;
                triggerInterval = setInterval(() => {
                    if (Date.now() - startTime >= 10000) {
                        clearInterval(triggerInterval);
                        setWheelRotate(false);
                        setButtonShow(false);
                        socketCommon.emit('disconnectUser', getUserId);

                        notify('success', data.message);

                        return;
                    }
                    socketCommon.emit('dialogae-now', {
                        id: user.id,
                        locale: i18n.language.split('-')[0],
                    });
                }, 5000); // Trigger every 1 second
            }
        };
        socketCommon.emit('dialogae-now', {
            id: user.id,
            locale: i18n.language.split('-')[0],
        });
        // if (socketCommon) {
        if (user.accountType === 'R' || user.accountType === 'S') {
            socketCommon.on('no-dialoguer', handleNoDialoguer);
        }

        socketCommon.on('close-interval', (data) => {
            clearInterval(triggerInterval);
            setWheelRotate(false);
            setButtonShow(false);
            // socketCommon.emit('disconnectUser', getUserId);
        });
        // }

        // Clean up the socket event listener when the component unmounts
        return () => {
            if (socketCommon) {
                socketCommon.off('no-dialoguer', handleNoDialoguer);
            }
        };
    };

    useEffect(() => {
        if (!isLoadingDialogaeNowTime) {
            if (new Date(dialogaeNowData?.dStartTime) <= new Date()) {
                setIsSpeakNowActive(true);
            } else {
                setIsSpeakNowActive(false);
            }
        }
    }, [
        dialogaeNowData,
        isLoadingDialogaeNowTime,
        isRefetchingDialogaeNowTime,
    ]);

    useEffect(() => {
        document.title = formatPageTitle(t('speak_now.speak_now'));
    }, [i18n.language]);

    return (
        <div className='speak-now'>
            <BreadcrumbAction lastPage={t('dashboard_nav.speak_now')} />

            <div className='content-wrapper text-center spinner-wrapper'>
                {isLoadingDialogaeNowTime ? (
                    <Loader innerLoader={true} />
                ) : (
                    <>
                        <div style={{ margin: '0 auto ' }}>
                            {isSpeakNowActive ? (
                                !buttonShow && <SpeakNowActive />
                            ) : (
                                <>
                                    <SpeakNowNotActive
                                        startTime={
                                            dialogaeNowData?.dStartTime || ''
                                        }
                                        endTime={dialogaeNowData?.dEndTime}
                                        wheelerHandle={wheelerHandle}
                                        refetchDialogaeNowTime={
                                            refetchDialogaeNowTime
                                        }
                                    />
                                </>
                            )}

                            {/* When Matching API is being called */}
                            {buttonShow && (
                                <div className='speak-content'>
                                    <h1>{t('dialog_now.we_are')}...</h1>
                                </div>
                            )}

                            {/* When no match has been found */}
                            {false && <NoDialoguerFound />}
                            {!isSpeakNowActive ? null : (
                                <div className='wheeler-action'>
                                    {!buttonShow && (
                                        <>
                                            <div className='wheeler-action mb-2'>
                                                <SpeakNowCountdown
                                                    isSeconds={false}
                                                    speakNowEndTime={
                                                        dialogaeNowData?.dEndTime
                                                    }
                                                    countDownTitle={t(
                                                        'dialog_now.ends_at'
                                                    )}
                                                />
                                            </div>
                                            <button
                                                onClick={wheelerHandle}
                                                className='primary-btn'
                                            >
                                                {t('speak_now.start')}
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className='call-wheeler'>
                            <CallWheeler wheelRotate={wheelRotate} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SpeakNow;
