import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getInterestCategories } from '../../../api/adapters/interest-category';
import { getUserProfile, updateProfile } from '../../../api/adapters/profile';
import { CommonContext } from '../../../context/CommonState';
import { updateUser } from '../../../redux/actions/user';
import {
    formatPageTitle,
    handleApiError,
    handleQueryError,
} from '../../../utils/helpers/common.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import ModalPopUp from '../../components/ModalPopUp';
import BreadcrumbAction from '../../components/common-component/BreadcrumbAction';
import InterestHeader from '../../components/common-component/InterestHeader';
import TitleHeader from '../../components/common-component/TitleHeader';
import InterestsWrapper from '../../components/interests-wrapper/InterestsWrapper';
import FormErrorLabel from '../../components/mini-components/FormErrorLabel';
import LoadingButton from '../../components/mini-components/LoadingButton';
import TextArea from '../../components/mini-components/TextArea';

function EditProfile() {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [modalShow, setModalShow] = useState(false);
    const [bio, setBio] = useState('');
    const [characterCount, setCharacterCount] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    // const [isFormModified, setIsFormModified] = useState(false);
    const {
        isFormModified,
        setIsFormModified,
        confirmModalTo,
        setShowConfirmModal,
        setConfirmModalTo,
        showConfirmModal,
        confirmNavigation,
    } = useContext(CommonContext);
    // const [showConfirmModal, setShowConfirmModal] = useState(false);
    // const [confirmModalTo, setConfirmModalTo] = useState(null);

    const [formErrors, setFormErrors] = useState({ sBio: '', aInterests: '' });

    const {
        data: userData,
        refetch: refetchUserData,
        isRefetching: isRefetchingUserData,
        error: userDataError,
    } = useQuery({
        queryKey: ['user-profile', i18n.language.split('-')[0]],
        queryFn: () =>
            getUserProfile(i18n.language.split('-')[0]).then((res) => res.data),
        enabled: false,
    });

    const { data: interestCategories, error: interestError } = useQuery({
        queryKey: ['interest-categories', i18n.language?.split('-')[0]],
        queryFn: () =>
            getInterestCategories(i18n.language?.split('-')[0]).then(
                (res) => res.data
            ),
        placeholderData: [],
    });

    const {
        mutate: mutateUpdateProfile,
        isLoading: isMutating,
        isSuccess: isUpdateSuccess,
    } = useMutation({
        mutationFn: (data) => updateProfile(i18n.language?.split('-')[0], data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetchUserData();
            setSelectedImage(null);
            setBio('');
            setSelectedItems([]);
            setIsFormModified(false);
        },
        onError: (error) => handleApiError(error, t, navigate, setFormErrors),
    });

    useEffect(() => {
        if (userDataError) handleQueryError(userDataError, navigate);
    }, [userDataError]);

    useEffect(() => {
        if (interestError) handleQueryError(interestError, navigate);
    }, [interestError]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isFormModified) {
                e.preventDefault();
                e.returnValue = 'Changes you made may not be saved.';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFormModified]);

    // const confirmNavigation = (to) => {
    //     const currentRoute = window.location.pathname;
    //     if (currentRoute === '/profile/edit' && isFormModified) {
    //         setShowConfirmModal(true);
    //         setConfirmModalTo(to);
    //     } else {
    //         navigate(to);
    //     }
    // };

    const handleConfirmModalHide = () => {
        setShowConfirmModal(false);
        setConfirmModalTo(null);
        setIsFormModified(true);
    };

    const handleDiscard = () => {
        setIsFormModified(false);
        navigate(confirmModalTo);
        handleConfirmModalHide();
    };
    const handleContinue = () => {
        handleConfirmModalHide();
    };

    // useEffect(() => {
    //     console.log('useEffect called');
    //     const handleBeforeUnload = (e) => {
    //         console.log('isFormModified', isFormModified, e);
    //         if (isFormModified) {
    //             e.preventDefault();
    //             e.returnValue = 'Changes you made may not be saved.';
    //             notify(
    //                 'error',
    //                 'Please save your changes before leaving the page.'
    //             );
    //         }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, [isFormModified]);

    const getImage = (e) => {
        const file = e.target.files[0];
        setIsFormModified(true);

        if (file) {
            setSelectedImage(file);

            const reader = new FileReader();

            reader.onload = (e) => {
                setPreviewImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSelect = (e) => {
        if (e.target.checked) {
            // Add New
            setSelectedItems((prevItems) => [...prevItems, e.target.name]);
            setIsFormModified(true);
        } else {
            // Remove Existing
            setSelectedItems((prevItems) =>
                prevItems.filter(
                    (interestItem) => interestItem !== e.target.name
                )
            );
            setIsFormModified(true);
        }
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const charactersCount = inputValue.length;
        setIsFormModified(true);

        if (charactersCount <= 100) {
            setBio(inputValue);
            setCharacterCount(charactersCount);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = formErrors;
        if (characterCount > 100) {
            errors.sBio = t('account_setup.error_bio_length');
        } else {
            errors.sBio = '';
        }

        if (selectedItems.length < 5) setModalShow(true);
        // errors.aInterests = t('account_setup.error_select_5_interests');
        else if (selectedItems.length > 12)
            errors.aInterests = t(
                'account_setup.error_select_only_12_interests'
            );
        else errors.aInterests = '';

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        const formData = new FormData();

        formData.append('sBio', bio);
        const isBioChanged = bio !== user.bio;

        // If Image is changed
        if (!/^https/i.test(previewImage))
            formData.append('uploadfile', selectedImage);

        selectedItems.forEach((element, index) => {
            formData.append(`aInterests[${index}]`, element);
        });

        const arr = [];
        user.interests.forEach((interest) => {
            interest.aInterestItems.forEach((interestItem) => {
                arr.push(interestItem._id);
            });
        });

        if (
            bio === user.bio &&
            JSON.stringify(selectedItems) === JSON.stringify(arr) &&
            !selectedImage
        ) {
            notify('info', t('profile.edit_profile_not_changed'));
            return;
        }

        if (isBioChanged) {
            mutateUpdateProfile(formData);
            refetchUserData();
            return;
        }
        mutateUpdateProfile(formData);
        setIsFormModified(false);
    };

    useEffect(() => {
        document.title = formatPageTitle(t('profile.edit_profile'));
    }, [i18n.language]);

    useEffect(() => {
        const arr = [];

        user.interests.forEach((interest) => {
            interest.aInterestItems.forEach((interestItem) => {
                arr.push(interestItem._id);
            });
        });

        setSelectedItems(arr);
        setPreviewImage(user.profilePicUrl);
    }, [user]);

    useEffect(() => {
        setBio(user.bio.trim());
        const trimmedBio = (user.bio || '').trim().replace(/\r/g, ''); // Remove only \r

        setCharacterCount(trimmedBio.length);
    }, [user.bio]);

    useEffect(() => {
        if (isUpdateSuccess) {
            const updatedUser = {
                ...user,
                bio: userData.sBio,
                interests: userData.aInterests,
                profilePicUrl:
                    userData?.sProfilePicUrl +
                    `${user?.profilePicUrl?.indexOf(' ') !== -1 ? '' : ' '}`,
            };

            // navigate('/profile');
            dispatch(updateUser(updatedUser));
        }
    }, [userData]);

    const handleCancel = () => {
        confirmNavigation('/profile');
        // setIsFormModified(false);
    };

    return (
        <>
            <div className='edit-profile'>
                <BreadcrumbAction
                    lastPage={t('profile.profile')}
                    currentPage={t('profile.edit_profile')}
                    lastPageLink='/profile'
                />

                <div className='content-wrapper'>
                    <TitleHeader
                        title={t('profile.edit_profile')}
                        btn_title={
                            isMutating ? (
                                <LoadingButton color='White' />
                            ) : (
                                t('save')
                            )
                        }
                        isBtnDisabled={isMutating || isRefetchingUserData}
                        rejectBtn={t('cancel')}
                        handleRejectClick={handleCancel}
                        btnClick={handleSubmit}
                        isBackBtn={() => confirmNavigation('/profile')}
                    />

                    <div className='row'>
                        <div className='col-sm-4  col-md-3'>
                            <div className='profile-dp edit-dp'>
                                <img
                                    src={`${
                                        previewImage ||
                                        '/images/profile-image-placeholder.svg'
                                    }`}
                                    alt=''
                                />
                                <input
                                    type='file'
                                    name=''
                                    id=''
                                    onChange={getImage}
                                    accept='image/*'
                                />
                            </div>
                            <div className='edit-profile-input'>
                                <button className='simple-btn'>
                                    {t('profile.edit')}
                                    <input
                                        type='file'
                                        name=''
                                        id=''
                                        onChange={getImage}
                                        accept='image/*'
                                    />
                                </button>
                            </div>
                        </div>
                        <div className='col-sm-8  col-md-9'>
                            <div className='user-details-wrapper'>
                                <TextArea
                                    label={t('profile.bio_optional')}
                                    value={bio}
                                    handleOnChange={handleInputChange}
                                    charactersCount={characterCount}
                                    placeholder={t(
                                        'field_name.bio_placeholder'
                                    )}
                                />
                                {formErrors.sBio ? (
                                    <FormErrorLabel message={formErrors.sBio} />
                                ) : null}

                                <div className='user-interest'>
                                    <h3> {t('profile.interests')}</h3>

                                    <div
                                        className=''
                                        style={{
                                            backgroundColor: '#F5F5F5',
                                            padding: '16px',
                                            borderRadius: '24px',
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: '#4d4d4d',
                                                paddingBottom: '24px',
                                            }}
                                        >
                                            {t('auth.between')}
                                        </p>

                                        <InterestHeader
                                            selectedItemsCount={
                                                selectedItems.length
                                            }
                                            handleClear={() => {
                                                setSelectedItems([]);
                                            }}
                                        />

                                        <InterestsWrapper
                                            interests={interestCategories}
                                            isSelectable={true}
                                            selectedItems={selectedItems}
                                            handleSelect={handleSelect}
                                        />

                                        {formErrors.aInterests ? (
                                            <FormErrorLabel
                                                message={formErrors.aInterests}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className='error-msg edit-profile-error justify-content-start'>
                                    <img
                                        src='/images/icons/error-ic.svg'
                                        alt='error-ic'
                                    />
                                    <p className='error'>
                                        {t('profile.in_case')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />

                        <h3 className='popup-title'>
                            {' '}
                            {t('account_setup.error_select_5_interests')}
                        </h3>

                        <p className='sm'></p>

                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={() => setModalShow(false)}
                            >
                                {t('ok')}
                            </button>
                        </div>
                    </>
                }
            />
            <ModalPopUp
                show={showConfirmModal}
                onHide={handleConfirmModalHide}
                modalcontent={
                    <>
                        <img src='/images/icons/warn-ic.svg' alt='' />
                        <h3 className='popup-title'>Cambios no guardados</h3>
                        <p className='sm'>
                            Los cambios que realizaste no sean han guardado.
                            ¿Estás seguro de que quieres salir?
                        </p>
                        <div className='popup-action'>
                            <button
                                className='primary-btn'
                                onClick={handleDiscard}
                            >
                                Descartar
                            </button>
                            <button
                                className='simple-btn'
                                onClick={handleContinue}
                            >
                                Continuar
                            </button>
                        </div>
                    </>
                }
            />
        </>
    );
}

export default EditProfile;
